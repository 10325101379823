import React from "react";
import '../../Styles/events.scss'

const EventsCard = ({image,title,when,place}) => {
    return(
        <div>
            <div className='row w-100 mt-5'>
                <div className='col-md-4'>
                    <img src={image} className='img-fluid'/>
                </div>
                <div className='col-md-8 d-flex flex-column justify-content-center'>
                    <p className='title-of-event'>{title}</p>
                    <p className='where-event'>{place}</p>
                    <p className='where-event'>{when}</p>
                </div>
            </div>
            <hr/>
        </div>
    )
}
export default EventsCard;
