import React from 'react';
import Lottie from 'react-lottie'
import animationData from '../assets/loading.json'

const Loading = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return(
        <div style={{width:'100vw',height:'100vh',overflowY:'hidden',backgroundColor:'#fcedc9'}} className='d-flex justify-content-center align-items-center'>
            <div className='d-flex justify-content-center align-items-center container flex-column'>
                <Lottie
                    options={defaultOptions}
                    width={'60%'}
                    height={'auto'}
                />
                <h1>Vila Luka Funtana</h1>
                <h2>Loading...</h2>
            </div>
        </div>
    )
}
export default Loading;
