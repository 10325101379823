import React from "react";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import BackgroundImageWithText from "../Components/BackgroundImageWithText";
import sportPic from '../assets/sport.jpg'
import SportCard from "../Components/SportCard";
import {useSelector} from "react-redux";

const Sport = () => {
    const sport=useSelector((state)=>state.DataReducer.Sport)
    const lang = useSelector((state) => state.LangReducer.lang);
    return(
        <div>
        <Navigation/>
        <BackgroundImageWithText image={sportPic} text='Sport'/>
            {
                sport.map((item)=>{
                    return <SportCard title={item.Title[0][lang]} text={item.Description[0][lang]} image={`${item.Picture.url}`}/>
                })
            }
        <Footer/>
        </div>
    )
}
export default Sport
