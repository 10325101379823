import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import LangReducer from './reducers/lang'
import DataReducer from "./reducers/data";

const rootReducer = combineReducers({
    LangReducer,DataReducer
});

const persistConfig = {
    key: "root",
    storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export default persistedReducer;
