import React from 'react';
import Navigation from "../Components/Navigation";
import Welcome from "../Components/Landing/welcome";
import Carousel from "../Components/Landing/Carousel";
import Distance from "../Components/Landing/Distance";
import Footer from "../Components/Footer";

const Landing = () => {
    return(
        <div style={{width:'100%',overflowY:'hidden'}}>
            <Navigation/>
            <Welcome/>
            <Carousel/>
            <hr/>
            <Distance/>
            <Footer/>
        </div>
    )
}
export default Landing;
