import React from "react";
import '../Styles/navbar.scss'
import { Navbar, Nav, Container,NavDropdown } from 'react-bootstrap';
import {useLanguageContext} from "../Context/LanguageContext";
import useTranslation from "../CustomHooks/translations";
import { useSelector,useDispatch } from "react-redux";
import {ChangeLang} from "../store/actions/lang";
import galeb from '../assets/seagull.gif'

const Navigation = () => {
    const lang = useSelector((state) => state.LangReducer.lang);
    const { language, changeLanguage } = useLanguageContext();
    changeLanguage(lang);
    const translation = useTranslation();
    const  dispatch=useDispatch();
    return(
       <Navbar className='color-nav' collapseOnSelect  variant='dark'  expand="lg">
           <Container>
               <Navbar.Brand href="/" style={{display:"flex",justifyContent:'center',alignItems:"center"}}>
                   <img
                       src={galeb}
                       width="auto"
                       height="75"
                       className="d-inline-block align-top"
                       alt="Seagull"
                   />Vila Luka Funtana</Navbar.Brand>
               <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
               <Navbar.Collapse id='responsive-navbar-nav'>
                   <Nav className='justify-content-end w-100 text-nav'>
                       <Nav.Link href='/reservations'>{translation.reservations}</Nav.Link>
                       <NavDropdown title={translation.activity} id="basic-nav-dropdown">
                           <NavDropdown.Item href="/beaches">{translation.beach}</NavDropdown.Item>
                           <NavDropdown.Item href="/culture">{translation.culture}</NavDropdown.Item>
                           <NavDropdown.Item href="/restaurants">{translation.randb}</NavDropdown.Item>
                           <NavDropdown.Item href="/Destinations">{translation.destandtrip}</NavDropdown.Item>
                           <NavDropdown.Item href="/sport">Sport</NavDropdown.Item>
                       </NavDropdown>
                       <NavDropdown title={language} id="basic-nav-dropdown">
                           <NavDropdown.Item onClick={()=>{changeLanguage("HR");dispatch(ChangeLang('HR'))}}>HR</NavDropdown.Item>
                           <NavDropdown.Item onClick={()=>{changeLanguage("ENG");dispatch(ChangeLang('ENG'))}}>ENG</NavDropdown.Item>
                           <NavDropdown.Item onClick={()=>{changeLanguage("GER");dispatch(ChangeLang('GER'))}}>GER</NavDropdown.Item>
                           <NavDropdown.Item onClick={()=>{changeLanguage("ITA");dispatch(ChangeLang('ITA'))}}>ITA</NavDropdown.Item>
                       </NavDropdown>
                   </Nav>
               </Navbar.Collapse>
           </Container>
       </Navbar>
    )
}
export default Navigation;
