import React from "react";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import BackgroundImageWithText from "../Components/BackgroundImageWithText";
import destination from '../assets/rovinj.jpg'
import Trips from "../Components/Destinations/Trips";
import City from "../Components/Destinations/City";
import useTranslation from "../CustomHooks/translations";
import {useSelector} from "react-redux";


const Destinations = () => {
    const translation = useTranslation();
    const destinations=useSelector((state)=>state.DataReducer.Destinations)
    const lang = useSelector((state) => state.LangReducer.lang);
    console.log(destinations)
    return(
        <div>
            <Navigation/>
            <BackgroundImageWithText image={destination} text={translation.destandtrip}/>
            <Trips/>
            <div className='container'>
                {
                    destinations.map((item,i)=>{
                        return <City image={`${item.Picture.url}`} name={item.Title} text1={item.Paragraph1[0][lang]} text2={item.Paragrah2[0][lang]} key={i}/>
                    })
                }
            </div>
            <Footer/>
        </div>
    )
}
export default Destinations;
