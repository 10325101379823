import React from "react";
import '../Styles/footer.scss'
 import useTranslation from "../CustomHooks/translations";

const Footer = () => {
    const translation = useTranslation();
    return(
        <div className='footer'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-8'>
                        <p style={{fontWeight:'bold'}}>{translation.contact}</p>
                        <p>vilalukafuntana@gmail.com</p>
                        <p>{translation.reservationNote}</p>
                </div>
                    <div className='col-md-4'>
                        <p>{translation.madeBy} <a href='https://github.com/Dekic2003' className='dekic2003'>Dekic2003</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;
