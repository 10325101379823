import React from "react";
import beach from '../../assets/sun-umbrella.svg'
import hotel from '../../assets/city.svg'
import shop from '../../assets/store.svg'

import '../../Styles/distance.scss'
import useTranslation from "../../CustomHooks/translations";

const Distance = () => {
    const translation = useTranslation();

    return(
        <div className='p-5'>
            <div className='container'>
                <h1 className='distance-text'>{translation.distance}</h1>
                <div className='row mt-5 pt-2'>
                    <div className='col-lg-4 d-flex justify-content-center align-items-center '>
                        <div className='card-distance d-flex justify-content-center align-items-center flex-column'>
                            <p className='distance-type'>{translation.beach}</p>
                            <img src={beach} alt='Distance' className='img-fluid'/>
                            <div className='w-100 d-flex align-items-center justify-content-between '>
                                <p className='distance-dest'>Funtana</p>
                                <p className='distance-far'>0.5 km</p>
                            </div>
                            <div className='w-100 d-flex align-items-center justify-content-between '>
                                <p className='distance-dest'>Hotel Funtana</p>
                                <p className='distance-far'>1 km</p>
                            </div>
                            <div className='w-100 d-flex align-items-center justify-content-between '>
                                <p className='distance-dest'>Polidor</p>
                                <p className='distance-far'>1 km</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 d-flex justify-content-center align-items-center'>
                        <div className='card-distance d-flex justify-content-center align-items-center flex-column'>
                            <p className='distance-type'>{translation.city}</p>
                            <img src={hotel} alt='Distance' className='img-fluid'/>
                            <div className='w-100 d-flex align-items-center justify-content-between '>
                                <p className='distance-dest'>Porec</p>
                                <p className='distance-far'>5 km</p>
                            </div>
                            <div className='w-100 d-flex align-items-center justify-content-between '>
                                <p className='distance-dest'>Rovinj</p>
                                <p className='distance-far'>30 km</p>
                            </div>
                            <div className='w-100 d-flex align-items-center justify-content-between '>
                                <p className='distance-dest'>Pula</p>
                                <p className='distance-far'>60 km</p>
                            </div>
                            <div className='w-100 d-flex align-items-center justify-content-between '>
                                <p className='distance-dest'>Premantura</p>
                                <p className='distance-far'>70 km</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 d-flex justify-content-center align-items-center'>
                        <div className='card-distance d-flex justify-content-center align-items-center flex-column'>
                            <p className='distance-type'>{translation.logistics}</p>
                            <img src={shop} alt='Distance' className='img-fluid'/>
                            <div className='w-100 d-flex align-items-center justify-content-between '>
                                <p className='distance-dest'>Plodine</p>
                                <p className='distance-far'>250m</p>
                            </div>
                            <div className='w-100 d-flex align-items-center justify-content-between '>
                                <p className='distance-dest'>{translation.postOffice}</p>
                                <p className='distance-far'>250m</p>
                            </div>
                            <div className='w-100 d-flex align-items-center justify-content-between '>
                                <p className='distance-dest'>{translation.ATM}</p>
                                <p className='distance-far'>250m</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Distance;
