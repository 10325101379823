export default {
    welcome:'Benvenuto a',
    gallery:'Galleria',
    reservations:'Prenotazioni',
    activity:'Attività',
    pets:'Animali domestici',
    parking:'Parcheggio',
    services:'Servizi',
    distance:'Distanza',
    beach:'Spiaggia',
    city:'Città',
    logistics:'Logistica',
    cream:'Crema',
    red:'Rosso',
    gray:'Grigio',
    orange:'Arancione',
    reservation:'Prenotazioni su',
    showMore:'Mostra altro',
    events:'Eventi',
    trips:'Viaggi',
    culture:'Cultura',
    randb:'Ristoranti e bar',
    destandtrip:'Destinazioni e viaggi',
    postOffice:'Ufficio postale',
    ATM:'Bancomat',
    bag:'Piscina e griglia',
    reservationNote:'Il contatto via e-mail è possibile solo in croato e inglese',
    contact:'Contatto',
    madeBy: 'Fatto da'
};
