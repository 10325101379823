import React, { useState, useContext } from "react";

const LanguageContext = React.createContext();
export const useLanguageContext = () => useContext(LanguageContext);

function LanguageContextPrivider({ children }) {
    const [language, changeLanguage] = useState("HR");
    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
}
export default LanguageContextPrivider;
