import React from "react";
import useTranslation from "../CustomHooks/translations";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import kultura from '../assets/kultura.svg'
import BackgroundImageWithText from "../Components/BackgroundImageWithText";
import Events from "../Components/Culture/Events";
import ParkCard from "../Components/Culture/ParkCard";
import {useSelector} from "react-redux";

const Culture = () => {
    const translation = useTranslation();
    const culture=useSelector((state)=>state.DataReducer.Culture)
    const lang = useSelector((state) => state.LangReducer.lang);
    return(
        <div>
            <Navigation/>
            <BackgroundImageWithText image={kultura} text={translation.culture}/>
            <Events/>
            {
               culture.map((item)=>{
                   console.log(item.Picture[0].url)
                   return <ParkCard title={item.Title} p1={item.Description[0][lang]} image={`${item.Picture[0].url}`} p2={item.Description2[0][lang]} image2={`${item.picture2.url}`}/>

               })
            }
            <Footer/>
        </div>
    )
}
export default Culture;
