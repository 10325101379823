import React from "react";
import '../../Styles/city.scss'



const City = ({last,name,text1,image,text2}) => {

    return(
        <div className='h-100 w-100 d-flex flex-column justify-content-center align-items-center p-2 mt-2'>
            <div className='container p-5'>
                <div className='row w-100'>
                    <div className='col-md-12'>
                        <p className='city'>{name}</p>
                        <p className='about-city'>{text1}</p>
                    </div>
                </div>
                <div className='d-block w-100'>
                    <img src={image} className='img-fluid w-100'/>
                </div>
                <div className='row w-100 pt-3'>
                    <div className='row w-100'>
                        <div className='col-md-12'>
                            <p className='about-city'>{text2}</p>
                        </div>
                    </div>
                </div>

            </div>
            <hr style={{border:'1px black solid',width:'80%'}} className={`${last?'d-none':'d-block'}`}/>
        </div>
    )
}
export default City
