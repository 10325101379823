export default {
    welcome:'Willkommen bei',
    gallery:'Galerie',
    reservations:'Reservierungen',
    activity:'Aktivität',
    pets:'Haustiere',
    parking:'Parken',
    services:'Dienstleistungen',
    distance:'Entfernung',
    beach:'Strand',
    city:'Städte',
    logistics:'Logistik',
    cream:'Sahne',
    red:'Rot',
    gray:'Grau',
    orange:'Orange',
    reservation:'Reservierungen am',
    showMore:'Mehr anzeigen',
    events:'Ereignisse',
    trips:'Reisen',
    culture:'Kultur',
    randb:'Restaurants und Bars',
    destandtrip:'Ziele und Reisen',
    postOffice:'Postamt',
    ATM:'Geldautomat',
    bag:'Pool & Grill',
    reservationNote:'Der Kontakt per E-Mail ist nur in kroatischer und englischer Sprache möglich',
    contact:'Kontakt',
    madeBy:'Hergestellt von'
};
