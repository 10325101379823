export const ACTIONS = {
    CHANGE_LANG: "CHANGE_LANG",

    //Data
    GET_BEACH_START:'GET_BEACH_START',
    GET_BEACH_SUCCESS:'GET_BEACH_SUCCESS',
    GET_BEACH_ERROR:'GET_BEACH_ERROR',

    GET_DESTINATION_START:'GET_DESTINATION_START',
    GET_DESTINATION_SUCCESS:'GET_DESTINATION_SUCCESS',
    GET_DESTINATION_ERROR:'GET_DESTINATION_ERROR',

    GET_TRIPS_START:'GET_TRIPS_START',
    GET_TRIPS_SUCCESS:'GET_TRIPS_SUCCESS',
    GET_TRIPS_ERROR:'GET_TRIPS_ERROR',

    GET_RESTAURANTS_START:'GET_RESTAURANTS_START',
    GET_RESTAURANTS_SUCCESS:'GET_RESTAURANTS_SUCCESS',
    GET_RESTAURANTS_ERROR:'GET_RESTAURANTS_ERROR',

    GET_SPORT_START:'GET_SPORT_START',
    GET_SPORT_SUCCESS:'GET_SPORT_SUCCESS',
    GET_SPORT_ERROR:'GET_SPORT_ERROR',

    GET_CULTURE_START:'GET_CULTURE_START',
    GET_CULTURE_SUCCESS:'GET_CULTURE_SUCCESS',
    GET_CULTURE_ERROR:'GET_CULTURE_ERROR',

    GET_GALLERY_START:'GET_GALLERY_START',
    GET_GALLERY_SUCCESS:'GET_GALLERY_SUCCESS',
    GET_GALLERY_ERROR:'GET_GALLERY_ERROR',

    GET_LANDING_START:'GET_LANDING_START',
    GET_LANDING_SUCCESS:'GET_LANDING_SUCCESS',
    GET_LANDING_ERROR:'GET_LANDING_ERROR',

    GET_KREM_SUCCESS:'GET_KREM_SUCCESS',
    GET_SIVI_SUCCESS:'GET_SIVI_SUCCESS',
    GET_ORANGE_SUCCESS:'GET_ORANGE_SUCCESS',
    GET_RED_SUCCESS:'GET_RED_SUCCESS',

    GET_KREM_GALLERY_SUCCESS:'GET_KREM_GALLERY_SUCCESS',
    GET_SIVI_GALLERY_SUCCESS:'GET_SIVI_GALLERY_SUCCESS',
    GET_ORANGE_GALLERY_SUCCESS:'GET_ORANGE_GALLERY_SUCCESS',
    GET_RED_GALLERY_SUCCESS:'GET_RED_GALLERY_SUCCESS',
    GET_PaG_GALLERY_SUCCESS:'GET_PaG_GALLERY_SUCCESS',


    SET_LOADING_TRUE:'SET_LOADING_TRUE',
    SET_LOADING_FALSE:'SET_LOADING_FALSE',

    GET_EVENTS_SUCCESS:'GET_EVENTS_SUCCESS'



};
