import React,{useState} from "react";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import '../Styles/reservations.scss'
import useTranslation from "../CustomHooks/translations";
import {useSelector} from "react-redux";

const Reservations = () => {
    const lang = useSelector((state) => state.LangReducer.lang);
    const [room,setRoom]=useState('krem');
    const cream=useSelector((state)=>state.DataReducer.krem)
    const gray=useSelector((state)=>state.DataReducer.sivi)
    const org=useSelector((state)=>state.DataReducer.orange)
    const red=useSelector((state)=>state.DataReducer.crveni)
    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }
    Date.prototype.subDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() - days);
        return date;
    }
    const Booked={
        krem:[],
        sivi:[],
        crveni:[],
        orange:[]
    }

    red.forEach((item)=>{
        Booked.crveni.push({after: new Date(item.Start).subDays(1),before: new Date(item.End).addDays(1)})
    })
    cream.forEach((item)=>{
        Booked.krem.push({after: new Date(item.Start).subDays(1),before: new Date(item.End).addDays(1)})
    })
    gray.forEach((item)=>{
        Booked.sivi.push({after: new Date(item.Start).subDays(1),before: new Date(item.End).addDays(1)})
    })
    org.forEach((item)=>{
        Booked.orange.push({after: new Date(item.Start).subDays(1),before: new Date(item.End).addDays(1)})
    })
    const translation = useTranslation();

    const FIRST_DAY = {
        ENG: 0,
        ITA: 0, // Use Monday as first day of the week
        GER:0,
        HR:0
    };
    const WEEKDAYS_LONG = {
        ENG: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ],
        ITA: [
            'Domenica',
            'Lunedì',
            'Martedì',
            'Mercoledì',
            'Giovedì',
            'Venerdì',
            'Sabato',
        ],
        GER: [ 'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        HR: [ 'nedjelja', 'ponedjeljak', 'utorak', 'srijeda', 'četvrtak', 'petak', 'subota' ],
    };
    const WEEKDAYS_SHORT = {
        ENG: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        ITA: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],
        GER: ['Mo','Di','Mi','Do','Fr','Sa','So'],
        HR:  ['P','U','S','Č','P','S','S']
    };
    const MONTHS = {
        ENG: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        GER: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Kann',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember',
        ],
        ITA: [
            'Gennaio',
            'Febbraio',
            'Marzo',
            'Aprile',
            'Maggio',
            'Giugno',
            'Luglio',
            'Agosto',
            'Settembre',
            'Ottobre',
            'Novembre',
            'Dicembre',
        ],
        HR: [
            'Siječanj',
            'Veljača',
            'Ožujak',
            'Travanj',
            'Svibanj',
            'Lipanj',
            'Srpanj',
            'Kolovoz',
            'Rujan',
            'Listopad',
            'Studeni',
            'Prosinac',
        ]
    };
    function formatDay(d, locale = 'ENG') {
        return `${WEEKDAYS_LONG[locale][d.getDay()]}, ${d.getDate()} ${
            MONTHS[locale][d.getMonth()]
        } ${d.getFullYear()}`;
    }

    function formatMonthTitle(d, locale = 'ENG') {
        return `${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;
    }

    function formatWeekdayShort(i, locale) {
        return WEEKDAYS_SHORT[locale][i];
    }
    function getFirstDayOfWeek(locale) {
        return FIRST_DAY[locale];
    }
    function formatWeekdayLong(i, locale) {
        return WEEKDAYS_SHORT[locale][i];
    }
    const localeUtils = {
        formatDay,
        formatMonthTitle,
        formatWeekdayShort,
        getFirstDayOfWeek,
        formatWeekdayLong,
    };
    return(
        <div>
            <Navigation/>
            <div className='container d-flex flex-column justify-content-center align-items-center p-5 '>
                <div className='row w-100 h-100'>
                    <div className={`col-lg-3 d-flex justify-content-center align-items-center ${room==='krem' ? "cream" : ""} }`}>
                        <p className={`rooms ${room==='krem' ? "rooms-selected cream" : ""} }`} onClick={()=>{setRoom('krem')}}>{translation.cream}</p>
                    </div>
                    <div className={`col-lg-3 d-flex justify-content-center align-items-center ${room==='crveni' ? "red" : ""} }`}>
                        <p className={`rooms ${room==='crveni' ? "rooms-selected red" : ""} }`} onClick={()=>{setRoom('crveni')}}>{translation.red}</p>
                    </div>
                    <div className={`col-lg-3 d-flex justify-content-center align-items-center ${room==='sivi' ? "gray" : ""} }`}>
                        <p className={`rooms ${room==='sivi' ? "rooms-selected gray" : ""} }`} onClick={()=>{setRoom('sivi')}}>{translation.gray}</p>
                    </div>
                    <div className={`col-lg-3 d-flex justify-content-center align-items-center ${room==='orange' ? "orange" : ""} }`}>
                        <p className={`rooms ${room==='orange' ? "rooms-selected orange" : ""} }`} onClick={()=>{setRoom('orange')}}>{translation.orange}</p>
                    </div>
                </div>
                <DayPicker numberOfMonths={2} disabledDays={Booked[room]}
                           locale={lang}
                           localeUtils={localeUtils}
                />
                <p className='reservation-on'>{translation.reservation}: vilalukafuntana@gmail.com</p>
                <p className='reservation-note' >{translation.reservationNote}</p>
            </div>
            <Footer/>
        </div>
    )
}
export default Reservations;
