import React from "react";
import '../Styles/beach.scss'



const PlazaCard = ({last,title,text,img1,img2,img3}) => {

    return(
        <div className='h-100 w-100 d-flex flex-column justify-content-center align-items-center p-5 mt-5'>
            <div className='container p-5'>
                <div className='row w-100'>
                    <div className='col-md-6'>
                        <p className='text-beach'>{title}</p>
                        <p className='text-beach-about'>{text}</p>
                    </div>
                    <div className='col-md-6'>
                        <img src={img1}  className='img-fluid'/>
                    </div>
                </div>
                <div className='row w-100'>
                    <div className='col-md-6'>
                        <img src={img2}  className='img-fluid'/>
                       </div>
                    <div className='col-md-6'>
                        <img src={img3}  className='img-fluid'/>
                    </div>
                </div>

            </div>
            <hr style={{border:'1px black solid',width:'80%'}} className={`${last?'d-none':'d-block'}`}/>
        </div>
    )
}
export default PlazaCard
