import { ACTIONS } from "../actions";

const initialState = {
    loading:false,
    Beaches:null,
    Destinations:null,
    Trips:null,
    RestourauntsAndBars:null,
    Sport:null,
    Culture:null,
    krem:null,
    crveni:null,
    sivi:null,
    orange:null,
    Landing:null,
    events:null,
    kremGallery:null,
    crveniGallery:null,
    siviGallery:null,
    orangeGallery:null,
    PaGGallery:null,
};

const DataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_LOADING_FALSE:
            let newState={
                ...state,
                loading: false,
            }
            return newState
        case ACTIONS.GET_BEACH_START:
            return {
                ...state,
            }
        case ACTIONS.GET_BEACH_SUCCESS:
            return {
                ...state,
                Beaches: action.payload,
            }
        case ACTIONS.GET_BEACH_ERROR:
            return {
                ...state,
                Beaches: null,
            }
        case ACTIONS.GET_CULTURE_START:
            return {
                ...state,
            }
        case ACTIONS.GET_CULTURE_SUCCESS:
            return {
                ...state,
                Culture: action.payload,
            }
        case ACTIONS.GET_CULTURE_ERROR:
            return {
                ...state,
                Culture: null,
            }
        case ACTIONS.GET_DESTINATION_START:
            return {
                ...state,
            }
        case ACTIONS.GET_DESTINATION_SUCCESS:
            return {
                ...state,
                Destinations: action.payload,
            }
        case ACTIONS.GET_DESTINATION_ERROR:
            return {
                ...state,
            }
        case ACTIONS.GET_SPORT_START:
            return {
                ...state,
            }
        case ACTIONS.GET_SPORT_SUCCESS:
            return {
                ...state,
                Sport: action.payload,
            }
        case ACTIONS.GET_SPORT_ERROR:
            return {
                ...state,
            }
        case ACTIONS.GET_TRIPS_START:
            return {
                ...state
            }
        case ACTIONS.GET_TRIPS_SUCCESS:
            return {
                ...state,
                Trips: action.payload,
            }
        case ACTIONS.GET_TRIPS_ERROR:
            return {
                ...state
            }
        case ACTIONS.GET_RESTAURANTS_START:
            return {
                ...state
            }
        case ACTIONS.GET_RESTAURANTS_SUCCESS:
            return {
                ...state,
                RestourauntsAndBars: action.payload,
            }
        case ACTIONS.GET_RESTAURANTS_ERROR:
            return {
                ...state
            }
        case ACTIONS.GET_LANDING_START:
            return {
                ...state
            }
        case ACTIONS.GET_LANDING_SUCCESS:
            return {
                ...state,
                Landing: action.payload,
            }
        case ACTIONS.GET_LANDING_ERROR:
            return {
                ...state
            }
        case ACTIONS.GET_ORANGE_GALLERY_SUCCESS:
            return {
                ...state,
                orangeGallery: action.payload
            }
        case ACTIONS.GET_RED_GALLERY_SUCCESS:
            return {
                ...state,
                crveniGallery: action.payload
            }
        case ACTIONS.GET_KREM_GALLERY_SUCCESS:
            return {
                ...state,
                kremGallery: action.payload
            }
        case ACTIONS.GET_SIVI_GALLERY_SUCCESS:
            return {
                ...state,
                siviGallery: action.payload
            }
        case ACTIONS.GET_PaG_GALLERY_SUCCESS:
            return {
                ...state,
                PaGGallery: action.payload
            }
        case ACTIONS.GET_KREM_SUCCESS:
            return {
                ...state,
                krem:action.payload,
            }
        case ACTIONS.GET_SIVI_SUCCESS:
            return {
                ...state,
                sivi: action.payload,
            }
        case ACTIONS.GET_ORANGE_SUCCESS:
            return {
                ...state,
                orange: action.payload,
            }
        case ACTIONS.GET_RED_SUCCESS:
            return {
                ...state,
                crveni: action.payload,
            }
        case ACTIONS.SET_LOADING_TRUE:
            return {...state,
                loading: true,
            }
        case ACTIONS.GET_EVENTS_SUCCESS:
            return {
                ...state,
                events: action.payload
            }

        default:
            return state;
    }

};
export default DataReducer;
