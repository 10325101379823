import React from "react";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import BackgroundImageWithText from "../Components/BackgroundImageWithText";
import useTranslation from "../CustomHooks/translations";
import bg from '../assets/rest.jpg'
import RestCard from "../Components/RestCard";
import {useSelector} from "react-redux";

const Restaurants = () => {
    const translation = useTranslation();
    const rest=useSelector((state)=>state.DataReducer.RestourauntsAndBars)
    const lang = useSelector((state) => state.LangReducer.lang);
    return(
        <div>
            <Navigation/>
            <BackgroundImageWithText image={bg} text={translation.randb}/>
            {
                rest.map((item)=>{
                    return <RestCard title={item.Title} text={item.Description[0][lang]} image={`${item.Picture.url}`}/>
                })
            }
            <Footer/>
        </div>
    )
}
export default Restaurants
