import React from "react";
import '../../Styles/trip.scss'

const TripCard = ({image,title,text,money}) => {
    return(
        <div>
            <div className='row w-100 mt-5'>
                <div className='col-md-4 d-flex justify-content-center align-items-center'>
                    <img src={image} className='img-fluid'/>
                </div>
                <div className='col-md-8 d-flex flex-column justify-content-center'>
                    <p className='trip-title'>{title}</p>
                    <p className='trip-text'>{text}</p>
                    <div className='d-flex flex-row justify-content-start align-items-center'>
                        <svg id="Component_10_1" data-name="Component 10 – 1" xmlns="http://www.w3.org/2000/svg" width="38.38" height="38.38" viewBox="0 0 38.38 38.38">
                            <path id="Path_64" data-name="Path 64" d="M0,0H38.38V38.38H0Z" fill="none"/>
                            <path id="Path_65" data-name="Path 65" d="M17.992,33.983A15.992,15.992,0,1,1,33.983,17.992,15.991,15.991,0,0,1,17.992,33.983Zm0-3.2A12.793,12.793,0,1,0,5.2,17.992,12.793,12.793,0,0,0,17.992,30.785Zm-5.6-9.595h8.8a.8.8,0,1,0,0-1.6h-6.4a4,4,0,1,1,0-8h1.6V8.4h3.2v3.2h4v3.2h-8.8a.8.8,0,1,0,0,1.6h6.4a4,4,0,1,1,0,8h-1.6v3.2h-3.2v-3.2h-4Z" transform="translate(1.198 1.198)" fill="#b5b54e"/>
                        </svg>
                        <p className='price'>{money}</p>
                    </div>
                </div>
            </div>
            <hr/>
        </div>
    )
}
export default TripCard;
