import React, {useState} from "react";
import useTranslation from "../../CustomHooks/translations";
import EventsCard from "./EventsCard";
import {useSelector} from "react-redux";

const Events = () => {
    const translation = useTranslation();
    const events=useSelector((state)=>state.DataReducer.events)
    const [slice,setSlice]=useState(1);
    return(
        <div className='w-100 '>
            <div className='container bg-event mt-2 pt-3 mb-5 pb-2'>
                <p className='events-title'>{translation.events}</p>
                <div>
                    {
                        events.slice(0,slice).map((item,i)=>{
                            return <EventsCard image={`${item.picture.url}`} title={item.Title} place={item.Location} when={new Date(item.Date).toLocaleString('en-US')} key={i}/>
                        })
                    }
                    <div className='d-flex justify-content-center align-items-center '>
                    <p className={`exp-btn ${(events.length<=slice)?'d-none':''}`}   onClick={()=>{setSlice(slice+1)}}>{translation.showMore}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Events
