import {ACTIONS} from "../actions";
import axios from 'axios'

const getData=()=>(dispatch)=>{
    const api=process.env.REACT_APP_API_LINK;
    dispatch({type:ACTIONS.SET_LOADING_TRUE});
    dispatch({type:ACTIONS.GET_BEACH_START,payload:''});
    axios.get(`${api}/beaches`)
        .then((res)=>dispatch({type:ACTIONS.GET_BEACH_SUCCESS,payload:res.data}))
        .catch((err)=>dispatch({type:ACTIONS.GET_BEACH_ERROR,payload:err}))

    dispatch({type:ACTIONS.GET_DESTINATION_START,payload:''});
    axios.get(`${api}/destinations`)
        .then((res)=>dispatch({type:ACTIONS.GET_DESTINATION_SUCCESS,payload:res.data}))
        .catch((err)=>dispatch({type:ACTIONS.GET_DESTINATION_ERROR,payload:err}))

    dispatch({type:ACTIONS.GET_TRIPS_START,payload:''});
    axios.get(`${api}/trips`)
        .then((res)=>dispatch({type:ACTIONS.GET_TRIPS_SUCCESS,payload:res.data}))
        .catch((err)=>dispatch({type:ACTIONS.GET_TRIPS_ERROR,payload:err}))

    dispatch({type:ACTIONS.GET_RESTAURANTS_START,payload:''});
    axios.get(`${api}/restouraunt-and-bars`)
        .then((res)=>dispatch({type:ACTIONS.GET_RESTAURANTS_SUCCESS,payload:res.data}))
        .catch((err)=>dispatch({type:ACTIONS.GET_RESTAURANTS_ERROR,payload:err}))

    dispatch({type:ACTIONS.GET_RESTAURANTS_START,payload:''});
    axios.get(`${api}/restouraunt-and-bars`)
        .then((res)=>dispatch({type:ACTIONS.GET_RESTAURANTS_SUCCESS,payload:res.data}))
        .catch((err)=>dispatch({type:ACTIONS.GET_RESTAURANTS_ERROR,payload:err}))

    dispatch({type:ACTIONS.GET_SPORT_START,payload:''});
    axios.get(`${api}/sports`)
        .then((res)=>dispatch({type:ACTIONS.GET_SPORT_SUCCESS,payload:res.data}))
        .catch((err)=>dispatch({type:ACTIONS.GET_TRIPS_ERROR,payload:err}))

    dispatch({type:ACTIONS.GET_CULTURE_START,payload:''});
    axios.get(`${api}/cultures`)
        .then((res)=>dispatch({type:ACTIONS.GET_CULTURE_SUCCESS,payload:res.data}))
        .catch((err)=>dispatch({type:ACTIONS.GET_CULTURE_ERROR,payload:err}))

    axios.get(`${api}/pool-and-grill-gallery`)
        .then((res)=>dispatch({type:ACTIONS.GET_PaG_GALLERY_SUCCESS,payload:res.data}))
        .catch((err)=>console.log(err))

    axios.get(`${api}/gray-gallery`)
        .then((res)=>dispatch({type:ACTIONS.GET_SIVI_GALLERY_SUCCESS,payload:res.data}))
        .catch((err)=>console.log(err))

    axios.get(`${api}/orange-gallery`)
        .then((res)=>dispatch({type:ACTIONS.GET_ORANGE_GALLERY_SUCCESS,payload:res.data}))
        .catch((err)=>console.log(err))

    axios.get(`${api}/red-gallery`)
        .then((res)=>dispatch({type:ACTIONS.GET_RED_GALLERY_SUCCESS,payload:res.data}))
        .catch((err)=>console.log(err))

    axios.get(`${api}/cream-gallery`)
        .then((res)=>dispatch({type:ACTIONS.GET_KREM_GALLERY_SUCCESS,payload:res.data}))
        .catch((err)=>console.log(err))

    dispatch({type:ACTIONS.GET_LANDING_START,payload:''});
    axios.get(`${api}/landing-image`)
        .then((res)=>dispatch({type:ACTIONS.GET_LANDING_SUCCESS,payload:res.data}))
        .catch((err)=>dispatch({type:ACTIONS.GET_LANDING_ERROR,payload:err}))

    axios.get(`${api}/reservations`)
        .then((res)=>dispatch({type:ACTIONS.GET_RED_SUCCESS,payload:res.data}))
        .catch((err)=>console.log(err))

    axios.get(`${api}/cream-reservations`)
        .then((res)=>dispatch({type:ACTIONS.GET_KREM_SUCCESS,payload:res.data}))
        .catch((err)=>console.log(err))

    axios.get(`${api}/orange-reservations`)
        .then((res)=>dispatch({type:ACTIONS.GET_ORANGE_SUCCESS,payload:res.data}))
        .catch((err)=>console.log(err))


    axios.get(`${api}/gray-reservations`)
        .then((res)=>dispatch({type:ACTIONS.GET_SIVI_SUCCESS,payload:res.data}))
        .catch((err)=>console.log(err))

    axios.get(`${api}/events`)
        .then((res)=>dispatch({type:ACTIONS.GET_EVENTS_SUCCESS,payload:res.data}))
        .catch((err)=>console.log(err))

    dispatch({type:ACTIONS.SET_LOADING_FALSE});
}
export default getData;
