import React from "react";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import PlazaCard from "../Components/PlazaCard";
import {useSelector} from "react-redux";

const Plaza = () => {
    const beaches=useSelector((state)=>state.DataReducer.Beaches)
    const lang = useSelector((state) => state.LangReducer.lang);

    console.log(beaches)
    return(
        <div>
            <Navigation/>
            {
                beaches.map((item,i)=>{
                    return(
                        <PlazaCard title={item.Title} text={item.Desription[0][lang]} img1={`${item.Picture1.url}`} img2={`${item.Picture2.url}`} img3={`${item.Picture3.url}`} last={i===beaches.length-1}/>
                    )
                })
            }
            <Footer/>
        </div>
    )
}
export default Plaza;
