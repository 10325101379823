export default {
  welcome:'Welcome to',
  gallery:'Gallery',
  reservations:'Reservations',
  activity:'Activity',
  pets:'Pets',
  parking:'Parking',
  services:'Services',
  distance:'Distance',
  beach:'Beach',
  city:'Cities',
  logistics:'Logistics',
  cream:'Cream',
  red:'Red',
  gray:'Gray',
  orange:'Orange',
  reservation:'Reservations on',
  showMore:'Show More',
  events:'Events',
  trips:'Trips',
  culture:'Culture',
  randb:'Restaurants and Bars',
  destandtrip:'Destinations and Trips',
  postOffice:'Post Office',
  ATM:'ATM',
  bag:'Pool & Grill',
  reservationNote: 'Contact by e-mail is possible only in Croatian and English',
  contact:'Contact',
  madeBy:'Made by'
};
