import React from "react";
import '../Styles/BackgroundImageWithText.scss'
const BackgroundImageWithText = ({image,text}) => {
    return(
        <div className='Image' style={{backgroundImage:`url(${image})`}}>
            <div className='ImageOverlay'>
                <div className='container justify-content-start align-items-center d-flex h-100'>
                    <p className='culture-text'>{text}</p>
                </div>
            </div>
        </div>
    )
}
export default BackgroundImageWithText;
