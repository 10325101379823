import React, {useState} from "react";
import useTranslation from "../../CustomHooks/translations";
import TripCard from "./TripCard";
import {useSelector} from "react-redux";

const Trips = () => {
    const translation = useTranslation();
    const trips=useSelector((state)=>state.DataReducer.Trips)
    const lang = useSelector((state) => state.LangReducer.lang);
    const [slice,setSlice]=useState(1);
    return(
        <div className='w-100 '>
            <div className='container bg-event mt-2 pt-3 mb-5 pb-2'>
                <p className='events-title'>{translation.trips}</p>
                <div>
                    {
                        trips.slice(0,slice).map((item,i)=>{
                            return <TripCard image={`${item.Picture.url}`} title={item.Title} text={item.Description[0][lang]} money={`${item.Price} €`}  key={i}/>
                        })
                    }
                    <div className='d-flex justify-content-center align-items-center '>
                        <p className={`exp-btn ${(trips.length<=slice)?'d-none':''}`}   onClick={()=>{setSlice(slice+1)}}>{translation.showMore}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Trips
