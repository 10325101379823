export default {
    welcome:'Dobrodošli na',
    gallery:'Galerija',
    reservations:'Rezervacije',
    activity:'Aktivnosti',
    pets:'Ljubimci',
    parking:'Parking',
    services:'Usluge',
    distance:'Udaljenosti',
    beach:'Plaža',
    city:'Gradovi',
    logistics:'Logistika',
    cream:'Krem',
    red:'Crveni',
    gray:'Sivi',
    orange:'Narandžasti',
    reservation:'Rezervacija na',
    showMore:'Prikaži više',
    events:'Događaji',
    trips:'Izleti',
    culture:'Kultura',
    randb:'Restorani i Barovi',
    destandtrip:'Destinacije i izleti',
    postOffice:'Pošta',
    ATM:'Bankomat',
    bag:'Bazen & Grill',
    reservationNote:'Kontaktiranje na mail može samo na hrvatskom i engleskom jeziku',
    contact:'Kontakt',
    madeBy:'Napravio'
};
