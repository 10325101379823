import React,{useState} from "react";
import {Carousel} from "react-bootstrap";
import useTranslation from "../../CustomHooks/translations";
import {useSelector} from "react-redux";



const Gallery = () => {
    const translation = useTranslation();
    const [room,setRoom]=useState('krem');
    const RED=useSelector((state)=>state.DataReducer.crveniGallery)
    const GRAY=useSelector((state)=>state.DataReducer.siviGallery)
    const CREAM=useSelector((state)=>state.DataReducer.kremGallery)
    const PaG=useSelector((state)=>state.DataReducer.PaGGallery)
    const ORANGE=useSelector((state)=>state.DataReducer.orangeGallery)
    const Gallery={
        krem:CREAM,
        sivi:GRAY,
        crveni:RED,
        orange:ORANGE,
        bag:PaG
    }

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return(
        <div className='w-100 h-100 p-5'>
            <div className='container'>
            <h1>{translation.gallery}</h1>
                <Carousel fade activeIndex={index} onSelect={handleSelect}>
                    {
                        Gallery[room].Pictures.map((item)=>{
                            return (
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={`${item.url}`}
                                    alt="Image"
                                />

                            </Carousel.Item>
                             )
                        })
                    }


                </Carousel>
                <div className='row w-100 h-100 mt-5'>
                    <hr/>
                    <div className={`col-lg d-flex justify-content-center align-items-center ${room==='krem' ? "cream" : ""} }`}>
                        <p className={`rooms ${room==='krem' ? "rooms-selected cream" : ""} }`} onClick={()=>{setRoom('krem');setIndex(0)}}>{translation.cream}</p>
                    </div>
                    <div className={`col-lg d-flex justify-content-center align-items-center ${room==='crveni' ? "red" : ""} }`}>
                        <p className={`rooms ${room==='crveni' ? "rooms-selected red" : ""} }`} onClick={()=>{setRoom('crveni');setIndex(0)}}>{translation.red}</p>
                    </div>
                    <div className={`col-lg d-flex justify-content-center align-items-center ${room==='sivi' ? "gray" : ""} }`}>
                        <p className={`rooms ${room==='sivi' ? "rooms-selected gray" : ""} }`} onClick={()=>{setRoom('sivi');setIndex(0)}}>{translation.gray}</p>
                    </div>
                    <div className={`col-lg d-flex justify-content-center align-items-center ${room==='orange' ? "orange" : ""} }`}>
                        <p className={`rooms ${room==='orange' ? "rooms-selected orange" : ""} }`} onClick={()=>{setRoom('orange');setIndex(0)}}>{translation.orange}</p>
                    </div>
                    <div className={`col-lg d-flex justify-content-center align-items-center ${room==='bag' ? "bag" : ""} }`}>
                        <p className={`rooms ${room==='bag' ? "rooms-selected bag" : ""} }`} onClick={()=>{setRoom('bag');setIndex(0)}}>{translation.bag}</p>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Gallery;
