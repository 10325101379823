import React from "react";
import '../../Styles/welcome.scss'
import pets from '../../assets/pets.svg'
import parking from '../../assets/parking.svg'
import wifi from '../../assets/wifi.svg'
import useTranslation from "../../CustomHooks/translations";
import {useSelector} from "react-redux";


const Welcome = () => {
    const translation = useTranslation();
    const landing=useSelector((state)=>state.DataReducer.Landing)
    return(
        <div className='HelloPicture' style={{backgroundImage:`url(${landing.Image.url})`}}>
            <div className='HelloOpacity'>
                <div className='container d-flex justify-content-center align-items-center h-100 w-100'>
                    <div className='row w-100 '>
                        <div className='col-md-8 h-100' data-aos="fade-up-right" data-aos-duration="2000">
                            <p className='welcome-text'>{translation.welcome}</p>
                            <p className='welcome-text-big'>Vila Luka Funtana</p>
                        </div>
                        <div className='col-md-4 services d-flex justify-content-center align-items-center flex-column h-100 p-5' data-aos="fade-up-left" data-aos-duration="2000" data-aos-delay="500">
                            <div className='row h-25 '>
                                <div className='col-lg-12'>
                                    <p className='services-text'>{translation.services}</p>
                                </div>
                            </div>
                                <div className='row w-100 h-75 '>
                                    <div className='col-md-4 d-flex justify-content-center align-items-center flex-column p-0 mt-1'>
                                        <img src={pets} className='img-fluid'/>
                                        <p className='m-0' style={{textAlign:'center'}}>{translation.pets}</p>
                                    </div>
                                    <div className='col-md-4 d-flex justify-content-center align-items-center flex-column p-0 mt-1'>
                                        <img src={parking} className='img-fluid'/>
                                        <p className='m-0'>{translation.parking}</p>
                                    </div>
                                    <div className='col-md-4 d-flex justify-content-center align-items-center flex-column p-0 mt-1'>
                                        <img src={wifi} className='img-fluid'/>
                                        <p className='m-0'>Wi-Fi</p>
                                    </div>

                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default Welcome;
