import React from "react";
import '../../Styles/parkCard.scss'

const ParkCard = ({image,image2,p1,p2,title}) => {

    return(
        <div className='container'>
            <div className='row w-100 mt-5'>
                <div className='col-md-7 d-flex flex-column justify-content-center'>
                    <p className='park-Name'>{title}</p>
                    <p className='park-text'>{p1}</p>
                </div>
                <div className='col-md-5'>
                    <img src={image} className='img-fluid'/>
                </div>

            </div>
            <div className='row w-100 mt-5'>
                <div className='col-md-5'>
                    <img src={image2} className='img-fluid'/>
                </div>
                <div className='col-md-7 d-flex flex-column justify-content-center'>
                    <p className='park-text'>{p2}</p>
                </div>
            </div>
            <hr/>
        </div>
    )
}
export default ParkCard;
