import { ACTIONS } from "../actions";

const initialState = {
    lang:'HR'
};

const LangReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.CHANGE_LANG:
            return {
                ...initialState,
                lang: action.payload,
            };
        default:
            return state;
    }
};
export default LangReducer;
