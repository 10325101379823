import React from "react";
import '../Styles/trip.scss'

const RestCard = ({image,title,text}) => {
    return(
        <div className='container'>
            <div className='row w-100 mt-5'>
                <div className='col-md-5'>
                    <img src={image} className='img-fluid'/>
                </div>
                <div className='col-md-7 d-flex flex-column justify-content-center'>
                    <p className='trip-title'>{title}</p>
                    <p className='trip-text'>{text}</p>
                </div>
            </div>
            <hr/>
        </div>
    )
}
export default RestCard;
