import React, {useEffect} from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import './App.css'
import AOS from 'aos';
import 'aos/dist/aos.css'

import Landing from "./Pages/Landing";
import Reservations from "./Pages/Reservations";
import Plaza from "./Pages/Plaza";
import Culture from "./Pages/Culture";
import Destinations from "./Pages/Destinations";
import Sport from "./Pages/Sport";
import Restaurants from "./Pages/Restaurants";

import {useDispatch, useSelector} from "react-redux";
import getData from './store/actions/data'
import Loading from "./Pages/Loading";



function App() {

    const dispatch=useDispatch();
    const dr=useSelector((state)=>state.DataReducer)

    useEffect(()=>{
        dispatch(getData())
        AOS.init();
    },[dispatch])

    if(dr.events===null){
        return <Loading/>
    }
    else {
    return (
      <div className='App'>
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Landing/>}/>
                  <Route path="/reservations" element={<Reservations/>}/>
                  <Route path="/beaches" element={<Plaza/>}/>
                  <Route path="/culture" element={<Culture/>}/>
                  <Route path="/destinations" element={<Destinations/>}/>
                  <Route path="/sport" element={<Sport/>}/>
                  <Route path="/restaurants" element={<Restaurants/>}/>
              </Routes>
          </BrowserRouter>
      </div>
  )}
  ;
}

export default App;
