import ENG from "./eng";
import HR from "./hr"
import GER from "./ger";
import ITA from "./ita";

export default {
    ENG,
    HR,
    GER,
    ITA

}
